$(document).ready(function () {
	var
		ua = navigator.userAgent.toLowerCase(),
		event = (ua.match(/Android/i) || ua.match(/iPad/i) || ua.match(/iPod/i) || ua.match(/iPhone/i)) ? "touchstart" : "click",
		isMobile = ua.match(/(iphone|ipod|ipad|android)/),
		window_w = $(window).width(),
		window_h = $(window).height(),
		is_home = $('body').hasClass('ishome');

	/* $.blockUI.defaults.message = LANG.aguarde;

	$.cookieBar({
		message: LANG.cookies_txt,
		acceptText: LANG.cookies_btn,
		fixed: true,
		bottom: true,
		policyButton: true,
		policyText: LANG.cp_txt,
		policyURL: LANG.cp_url,
	}); */

	$('html').removeClass('no-js');

	if (("standalone" in window.navigator) && window.navigator.standalone) {
		$('html').addClass('ios-standalone');
	}

	if ($.browser.msie) {
		$('html').addClass('is-ie');
		$('html').addClass('ie-' + $.browser.versionNumber);
	}

	if ($.browser.msedge) {
		$('html').addClass('is-edge');
	}

	if ($.browser.iphone && $.browser.safari) {
		$('html').addClass('ios-safari');
	}

	if ($.browser.iphone && $.browser.chrome) {
		$('html').addClass('ios-chrome');
	}

	if ($.browser.android && $.browser.chrome) {
		$('html').addClass('android-chrome');
	}

	/**
	 * Embed responsive
	 */
	var iframe = $('.text iframe');
	if (iframe.length) {
		iframe.wrap("<div class='embed'></div>");
	}

	/**
	 * Trigger AOS
	 */
	setTimeout(function () {
		/*$('#loader').fadeOut(500, function () {
			$(this).remove();
		});*/
		AOS.init({
			'offset': -50,
			disable: 'mobile'
		});
	}, 300);

	/**
	 * Fade loading images
	 */
	/*var load_images_bg = $('body').find('.load-img:not(.loaded), .load-img-uncover:not(.loaded)');
	if (load_images_bg.length) {
		load_images_bg.each(function (index, element) {
			var image = new Image(),
				$element = $(element),
				is_bg_or_img = typeof $element.data('src') !== 'undefined';

			image.onload = function () {
				if (is_bg_or_img) {
					$element.css("backgroundImage", "url('" + $element.attr("data-src") + "')").removeAttr('data-src');
				}
				setTimeout(function () {
					$element.parent().removeClass('loading');
					$element.addClass('loaded');
				}, 200);
			};
			image.src = is_bg_or_img ? $element.attr("data-src") : $element.find('img').attr('src');
		});
	}*/

	/**
	 * Lazy load images
	 */
	var velcroLazy = {
        /**
         * init
		 * correr on load e sempre que for necessário, por exemplo, a seguir a uma call ajax
		 * elements: passar class dos ementos a fazer lazy load (ex: .lazy)
		 * ex: velcroLazy.init('.lazy');
         */
        init: (elements) => {
            let lazyImages = $(elements);

			if ("IntersectionObserver" in window) {
				var lazyImageObserver = new IntersectionObserver(function(entries, observer) {
					entries.forEach(function(entry) {
						if (entry.isIntersecting) {
							var lazyImage = $(entry.target),
								image = lazyImage.find('img')[0] || new Image(),
								is_bg_or_img = typeof lazyImage.data('src') !== 'undefined';

							image.onload = function () {
								if (is_bg_or_img) {//is bg
									lazyImage.css("backgroundImage", "url('" + lazyImage.data("src") + "')").removeAttr('data-src');
								}
								setTimeout(function () {
									lazyImage.addClass('loaded').removeClass("lazy").parent().removeClass('loading');
								}, 200);
							};

							image.src = is_bg_or_img ? lazyImage.data("src") : lazyImage.find('img').attr('data-src');
							lazyImageObserver.unobserve(entry.target);
						}
					});
				});

				lazyImages.each(function() {
					lazyImageObserver.observe(this);
				});
			} else {
				lazyImages.each(function() {
					var is_bg_or_img = typeof $(this).data('src') !== 'undefined';
					if (is_bg_or_img) {//is bg
						$(this).css("backgroundImage", "url('" + $(this).data("src") + "')").removeAttr('data-src');
					}else{//is img
						$(this).find('img').attr("src", $(this).find('img').data("src")).removeAttr('data-src');
					}
					$(this).addClass('loaded').removeClass("lazy").parent().removeClass('loading');
				});
			}
        }
	}

	velcroLazy.init('.lazy');

	var root = $('html,body');
	function smoothScrollingTo(target) {

		var target = $(target);
		if (target.length) {
			root.animate({
				scrollTop: target.offset().top - 100
			}, 400, "swing");
		}
	}


	const swiper = new Swiper(".inner-fleet", {
		effect: "slide",
		slidesPerView: 1,
		//spaceBetween: 60,
		loop: true,
		setWrapperSize: true,
		autoplay: {
			delay: 3000,
			disableOnInteraction: true,
		},
		//centeredSlides: true,
		// width: 1500,
		//autoplay: {
		//	delay: 3000,
		//	disableOnInteraction: true,
		//  },
		//allowTouchMove: false,
		//pagination: {
		//	el: ".single-swiper-pagination",
		//	clickable: true,
		//},
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
		breakpoints: {
			// when window width is >= 320px
			350: {
			  slidesPerView: 1,
			  spaceBetween: 20
			},
			// when window width is >= 480px
			480: {
			  slidesPerView: 2,
			  spaceBetween: 10
			},
			// when window width is >= 640px
			860: {
			  slidesPerView: 3,
			  spaceBetween: 60
			}
		  }
	});


	/* $('a[href*=\\#]:not([href=\\#],[data-toggle],.open-popup)').on('click', function (event) {
		smoothScrollingTo(this.hash);
		if ($('.menu-mobile').hasClass('show')) {
			toogleMenu();
		}
	}); */

	//smoothScrollingTo(location.hash);

	$('.menu a').on('click', function(e){
		e.preventDefault();
		var index = $(this).parent().index();
		$('.menu').find('li.active').removeClass('active');
		$('.menu').find('li:eq('+index+')').addClass('active');
		smoothScrollingTo(this.hash); 
	});

	if (!Modernizr.objectfit) {
		$('body .objectfit').each(function () {
			var $container = $(this),
				imgUrl = $container.find('img').prop('src');
			if (imgUrl) {
				$container
					.css('backgroundImage', 'url(' + imgUrl + ')')
					.addClass('compat-object-fit');
			}
		});
	}

	/**
	 * Scroll do menu
	 */
	windowOnScroll();
	$(window).on('scroll', function () {
		windowOnScroll();
	});

});

/**
 * Block scroll
 * @param block
 */
function blockScroll(block) {
	if (block == 1) {
		$('html').addClass('block-scroll');
	} else if (block == 0) {
		$('html').removeClass('block-scroll');
	}
}

/**
 * Verificar se é um email válido
 */
function IsEmail(email) {
	var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email) ? true : false;
}

/**
 * Verifica se a checkbox de política de privacidade está ou não seleccionada
 */
function checkPolicy(form) {
	var checked = form.find('input[name="checkpolicy[]"]:checked').length;
	if (checked === 1) {
		return true;
	}

	return false;
}

/**
 * Window on scroll
 */
function windowOnScroll() {
	if ($(window).scrollTop() > 300 && !$('body').hasClass('scrolled')) {
		$('body').addClass('scrolled');
	} else if ($(window).scrollTop() <= 300 && $('body').hasClass('scrolled')) {
		$('body').removeClass('scrolled');
	}
}

var form = new Vue({
	el: "#form",
	data: {
		feedback: {
			message: '',
			class: ''
		}
	},
	methods: {
		/**
		 * Submeter
		 */
		submitForm: function(e){
			var $this = this;
			var fields = $(e.target).serialize();
			var action = 'send.php';
			$this.feedback.message = '';

			$.blockUI({
				message: '<h5>Stand by</h5>'
			});

			$.ajax({
				url: action,
				type: 'POST',
				data: fields,
				dataType: 'json',
				success: function(r){
					if(r.class == 'ok'){
						$(e.target)[0].reset();
					}
					$this.feedback.message = r.message;
					$this.feedback.class = r.class;
					$.unblockUI();
				},
				error: function(r) {
					$this.feedback.message = 'Error...';
					$this.feedback.class = 'error';
					$.unblockUI();
				}
			});
		}
	}
});